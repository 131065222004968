import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./views/home";
import Package_list from "./views/Package_list";
import Address_details from "./views/Address_details";
import Payment_details from "./views/Payment_details";
import Shipment_created from "./views/Shipment_created";
import Tracking from "./views/Tracking";
import Index from "./views/Index";
import Shipment_steps from "./views/Shipment_steps";
import Customer_index from "./views/Customer_index";
import My_Shipment from "./views/My_Shipment";
import Print_invoice from "./views/Print_invoice";
import Print_receipt from "./views/Print_receipt";
import Shipment_detail from "./views/Shipment_detail";
import Customer_profile from "./views/Customer_profile";
import Edit_customer_profile from "./views/Edit_customer_profile";
import My_wallet from "./views/My_wallet";
import Checkout from "./views/Checkout";
import Customer_settings from "./views/Customer_settings";
import Change_password from "./views/Change_password";
import Login from "./views/Login";
import Register from "./views/Register";
import Shipping_form from "./views/Shipping_form";
import Demo_page from "./views/demo_page";
import Get_quote from "./views/Get_quote";
import Print_label from "./views/Print_label";
import Cod_wallet from "./views/Cod_wallet";
import My_address from "./views/My_address";
import Ship_1 from "./views/Ship_1";
import Ship_2 from "./views/Ship_2";
import My_orders from "./views/My_orders";
import Summary from "./views/Summary";
import Developer_account from "./views/Developer_account";
import Ndr_list from "./views/Ndr_list";
import Weight_discrepancies from "./views/Weight_discrepancies";
import Customer_Login from "./views/Customer/Login";
import Customer_register from "./views/Customer/Customer_register";
import Dashboard from "./views/Customer/Dashboard";
// import Payout from "./views/Customer/Payout";
import Customer_orders from "./views/Customer/Customer_orders";
import Customer_shipments from "./views/Customer/Customer_shipments";
import Create_order_1 from "./views/Customer/Create_order_1";
import Create_order_2 from "./views/Customer/Create_order_2";
import Create_order_3 from "./views/Customer/Create_order_3";
import Create_order_4 from "./views/Customer/Create_order_4";
import Customer_warehouse from "./views/Customer/Customer_warehouse";
import Order_import from "./views/Customer/Order_import";
import Customer_package from "./views/Customer/Customer_package";
import Customer_shipment_detail from "./views/Customer/Customer_shipment_detail";
import Billing_shipping_charges from "./views/Customer/Billing_shipping_charges";
import Billing_cod_remittance from "./views/Customer/Billing_cod_remittance";
import Billing_wallet from "./views/Customer/Billing_wallet";
import Billing_wallet_recharge from "./views/Customer/Billing_wallet_recharge";
import Wallet_recharge from "./views/Customer/Wallet_recharge";
import Wallet_payment_success from "./views/Customer/Wallet_payment_success";
// import Wallet_stripe_status_check from "./views/Customer/Wallet_stripe_status_check";
import Wallet_payment_link_show from "./views/Customer/Wallet_payment_link_show";
import Settings from "./views/Customer/Settings";
import Channel_list from "./views/Customer/Channel_list";
import Channel_add from "./views/Customer/Channel_add";
import Channel_integrate from "./views/Customer/Channel_integrate";
import Customer_order_detail from "./views/Customer/Customer_order_detail";
import Tools from "./views/Customer/Tools";
import Rate_calculator from "./views/Customer/Rate_calculator";
import Rate_package from "./views/Customer/Rate_package";
import Bulk_order_log from "./views/Customer/Bulk_order_log";
import Bulk_orders_log_detail from "./views/Customer/Bulk_orders_log_detail";
import Customer_kyc from "./views/Customer/Customer_kyc";
import Verify_email from "./views/Customer/Verify_email";
import Verify_sms from "./views/Customer/Verify_sms";
// import Shopify_orders from './views/Shopify_orders';
import Tickets from "./views/Customer/Tickets";
import Create_ticket from "./views/Customer/Create_ticket";
import Ticket_details from "./views/Customer/Ticket_details";
import Label_settings from "./views/Customer/Label_settings";
import Label_size_settings from "./views/Customer/Label_size_settings";

import Reports from "./views/Customer/Reports";
import Download_label from "./views/Download_label";
import Customer_invoice from "./views/Customer/Customer_invoice";
import Invoice_vendor from "./views/Customer/invoice_vendor";
import After_ship from "./views/Customer/After_ship";
import Aftership_tracking_script from "./views/Customer/Aftership_tracking_script";
import Forgot_password from "./views/Customer/Forgot_password";
import Create_password from "./views/Customer/Create_password";
import Kyc from "./views/Customer/Kyc";
import Bank_details from "./views/Customer/Bank_details";
import Customer_bank_new from "./views/Customer/Customer_bank_new";
// import Allocation from "./views/Customer/Allocation";
// import Allocation_rule from "./views/Customer/Allocation_rule";
import Stripe_checkout_page from "./views/Customer/Stripe_checkout_page";
import Create_order from "./views/Customer/Create_order";
import Weight_mismatch from "./views/Customer/Weight_mismatch";
import Customer_b2b_orders from "./views/Customer/Customer_b2b_orders";
import Customer_b2b_package from "./views/Customer/Customer_b2b_package";
import Boxes from "./views/Customer/Boxes";
import Easebuzz_success from "./views/Customer/Easebuzz_success";
import Easebuzz_failure from "./views/Customer/Easebuzz_failure";
import Transaction_detail from "./views/Customer/Transaction_detail";
import config from "./config";
import Allocation from "./views/Customer/Allocation";

function App() {
  React.useEffect(() => {
    console.log(
      "config.REACT_APP_FRANCHISE_BUILD==",
      config.REACT_APP_FRANCHISE_BUILD
    );
    if (config.REACT_APP_FRANCHISE_BUILD == true) {
      // If franchise build is true, change primary color
      document.documentElement.style.setProperty("--primary-color", "#FF5733"); // New color for franchise build
    } else {
      // Default color
      document.documentElement.style.setProperty("--primary-color", "#7B2CBF");
    }
  }, []);
  const userDetail = localStorage.getItem("ship_rocket_user");
  const isLoggedIn = Boolean(userDetail);

  // console.log("userDetail 111===>   ",userDetail);
  return (
    <Router>
      <Routes>
        {/* <Route exact path='/' element={<Index />} /> */}

        {/* <Route exact path='/' element={<Home />} /> */}
        {isLoggedIn ? (
          <Route
            path="/"
            element={<Navigate to="/customer/dashboard" replace />}
          />
        ) : (
          <Route path="/" element={<Navigate to="/customer/login" replace />} />
        )}
        <Route exact path="/package_list" element={<Package_list />} />
        <Route exact path="/address_details" element={<Address_details />} />
        <Route exact path="/payment_details" element={<Payment_details />} />
        <Route exact path="/shipment_created" element={<Shipment_created />} />
        <Route exact path="/tracking/:tracking_no" element={<Tracking />} />
        <Route exact path="/shipment_steps" element={<Shipment_steps />} />
        <Route exact path="/home" element={<Customer_index />} />
        <Route exact path="/my_shipments" element={<My_Shipment />} />
        <Route exact path="/my_orders" element={<My_orders />} />
        {/* <Route exact path="/customer/payout" element={<Payout />} /> */}

        <Route
          exact
          path="/print_invoice/:shipment_id"
          element={<Print_invoice />}
        />
        <Route
          exact
          path="/print_receipt/:shipment_id"
          element={<Print_receipt />}
        />
        <Route
          exact
          path="/shipment_detail/:shipment_id"
          element={<Shipment_detail />}
        />
        <Route exact path="/profile" element={<Customer_profile />} />
        <Route
          exact
          path="/edit_profile/:cust_id"
          element={<Edit_customer_profile />}
        />
        <Route exact path="/my_wallet" element={<My_wallet />} />
        <Route exact path="/my_cod_wallet" element={<Cod_wallet />} />

        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/settings" element={<Customer_settings />} />
        <Route exact path="/change_password" element={<Change_password />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/shipping_form" element={<Shipping_form />} />

        <Route exact path="/get_quote" element={<Get_quote />} />
        <Route exact path="/demo" element={<Demo_page />} />
        <Route
          exact
          path="/print_label/:shipment_id"
          element={<Print_label />}
        />
        <Route
          exact
          path="/download_label/:shipment_id"
          element={<Download_label />}
        />

        <Route exact path="/my_address_book" element={<My_address />} />
        <Route exact path="/ship_1" element={<Ship_1 />} />
        <Route exact path="/ship_2" element={<Ship_2 />} />
        <Route exact path="/summary/:record_id" element={<Summary />} />
        <Route
          exact
          path="/customer/developer_settings"
          element={<Developer_account />}
        />
        <Route exact path="/ndr_list" element={<Ndr_list />} />
        <Route exact path="/customer/allocation" element={<Allocation />} />
        <Route
          exact
          path="/weight_discrepancies"
          element={<Weight_discrepancies />}
        />
        <Route exact path="/customer/login" element={<Customer_Login />} />
        <Route
          exact
          path="/customer/registration"
          element={<Customer_register />}
        />
        <Route exact path="/customer/dashboard" element={<Dashboard />} />
        <Route exact path="/customer/orders" element={<Customer_orders />} />
        <Route
          exact
          path="/customer/shipments"
          element={<Customer_shipments />}
        />
        <Route
          exact
          path="/customer/create_order_1"
          element={<Create_order_1 />}
        />
        <Route
          exact
          path="/customer/create_order_2"
          element={<Create_order_2 />}
        />
        <Route
          exact
          path="/customer/create_order_3"
          element={<Create_order_3 />}
        />
        <Route
          exact
          path="/customer/create_order_4"
          element={<Create_order_4 />}
        />
        <Route
          exact
          path="/customer/warehouse"
          element={<Customer_warehouse />}
        />
        <Route exact path="/customer/order_import" element={<Order_import />} />
        <Route exact path="/customer/kyc" element={<Kyc />} />
        <Route exact path="/customer/bank_details" element={<Bank_details />} />
        {/* <Route exact path="/customer/allocation" element={<Allocation />} /> */}
        {/* <Route
          exact
          path="/customer/allocation_rule"
          element={<Allocation_rule />}
        /> */}

        <Route
          exact
          path="/customer/customer_bank"
          element={<Customer_bank_new />}
        />

        <Route
          exact
          path="/customer/create_password"
          element={<Create_password />}
        />

        <Route
          exact
          path="/customer/forgot-otp"
          element={<Forgot_password />}
        />
        <Route
          exact
          path="/customer/easebuzz_success"
          element={<Easebuzz_success />}
        />
        <Route
          exact
          path="/customer/easebuzz_failure"
          element={<Easebuzz_failure />}
        />

        <Route
          exact
          path="/customer/transaction_detail/:payout_id"
          element={<Transaction_detail />}
        />

        <Route
          exact
          path="/customer/package_list"
          element={<Customer_package />}
        />
        <Route
          exact
          path="/customer/shipment_detail/:shipment_id"
          element={<Customer_shipment_detail />}
        />
        <Route
          exact
          path="/customer/billing_shipping_charges"
          element={<Billing_shipping_charges />}
        />
        <Route
          exact
          path="/customer/billing_cod_remittance"
          element={<Billing_cod_remittance />}
        />
        <Route
          exact
          path="/customer/billing_wallet"
          element={<Billing_wallet />}
        />
        <Route
          exact
          path="/customer/billing_wallet_recharge"
          element={<Billing_wallet_recharge />}
        />
        <Route
          exact
          path="/customer/wallet_recharge"
          element={<Wallet_recharge />}
        />
        <Route
          exact
          path="/customer/payment_transaction_status/:transactionId"
          element={<Wallet_payment_success />}
        />
        <Route
          exact
          path="/customer/Wallet_payment_link_show"
          element={<Wallet_payment_link_show />}
        />

        <Route exact path="/customer/settings" element={<Settings />} />
        <Route exact path="/customer/channel_list" element={<Channel_list />} />
        <Route exact path="/customer/add_channel" element={<Channel_add />} />
        <Route
          exact
          path="/customer/channel_integrate/:channel_id"
          element={<Channel_integrate />}
        />
        <Route
          exact
          path="/customer/order_detail/:order_number"
          element={<Customer_order_detail />}
        />
        <Route exact path="/customer/tools" element={<Tools />} />
        <Route
          exact
          path="/customer/rate_calculator"
          element={<Rate_calculator />}
        />
        <Route exact path="/customer/rate_package" element={<Rate_package />} />
        <Route
          exact
          path="/customer/bulk_orders_log/:log_id"
          element={<Bulk_order_log />}
        />
        <Route
          exact
          path="/customer/bulk_orders_logs"
          element={<Bulk_order_log />}
        />

        <Route
          exact
          path="/customer/bulk_orders_log_detail/:log_id"
          element={<Bulk_orders_log_detail />}
        />
        <Route exact path="/customer/customer_kyc" element={<Customer_kyc />} />
        <Route exact path="/customer/verify_email" element={<Verify_email />} />
        <Route exact path="/customer/verify_sms" element={<Verify_sms />} />

        <Route exact path="/customer/tickets" element={<Tickets />} />
        <Route exact path="/customer/create_order" element={<Create_order />} />

        <Route
          exact
          path="/customer/create_ticket"
          element={<Create_ticket />}
        />
        <Route
          exact
          path="/customer/ticket_details/:ticket_id"
          element={<Ticket_details />}
        />
        <Route
          exact
          path="/customer/label_settings"
          element={<Label_settings />}
        />

        <Route
          exact
          path="/customer/label_size_settings"
          element={<Label_size_settings />}
        />

        <Route exact path="/customer/reports" element={<Reports />} />
        <Route exact path="/customer/invoices" element={<Customer_invoice />} />
        <Route exact path="/customer/boxes" element={<Boxes />} />

        <Route
          exact
          path="/customer/b2b_package_list"
          element={<Customer_b2b_package />}
        />

        <Route
          exact
          path="/customer/b2b_orders"
          element={<Customer_b2b_orders />}
        />

        <Route
          exact
          path="/customer/invoice_vendor"
          element={<Invoice_vendor />}
        />
        <Route exact path="/customer/aftership" element={<After_ship />} />
        <Route
          exact
          path="/customer/weight_mismatch"
          element={<Weight_mismatch />}
        />

        <Route
          exact
          path="/customer/aftership_tracking_script"
          element={<Aftership_tracking_script />}
        />
        <Route
          exact
          path="/customer/stripe_checkout"
          element={<Stripe_checkout_page />}
        />
        {/* <Route
          exact
          path="/customer/payment_stripe_transaction_status/:transaction_id/:session_id"
          element={<Wallet_stripe_status_check />}
        /> */}
      </Routes>
    </Router>
  );
}

export default App;
