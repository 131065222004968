import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { useNetworkState } from 'react-use';
import UseProgressTracker from './UseProgressTracker'
import Footer from './Footer'
import UseBulkShipmentTracker from './UseBulkShipmentTracker';

const Tools = () => {

    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const { showProgressBar, completedOrders, totalOrders, recordId, status, toggleProgressBar } = UseProgressTracker();
    const{showorderBar, completedOrdersbulk, totalOrdersbulk, logidstate, statusstateorder, toggleProgressBarbulk}= UseBulkShipmentTracker();
  return (
    <div>
    <Left_menu value={10}/>

    <section class="home ">
 <Header/>

        

        <section class="mb-3 mt-3 tools">
          <div class="container">
            <h3 class="py-3">Tools</h3>
            <div class="row">
                <div class="col-12 col-md-4 mb-3">
                    <a onClick={()=>navigate({
            pathname: '/customer/rate_calculator',
            
          })} style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">calculate</i>
                                    </div>
                                </div>
                                <h5>Rate Calculator</h5>
                                <small>Calculate Shipping Rates in real time</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="/customer/allocation" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div className='row'>
                                    <div className='col-md-6'>
                                    <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">attractions</i>
                                    </div>
                                </div>
                                    </div>
                                    {/* <div className='col-md-6 mt-3'>
                                     <h6>Coming Soon</h6>
                                    </div> */}
                                </div>
                              
                                <h5>Order Allocation Engine</h5>
                                <small>Setup your Order Allocation Engine</small>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 col-md-4 mb-3">
                    <a href="/customer/aftership" class="" style={{textDecoration:"none"}}>
                        <div class="card border-0 shadow-sm">
                            <div class="card-body ">
                                <div className='row'>
                                  <div className='col-md-6'>
                                  <div class="iconset">
                                    <div class="icon color-2">
                                        <i class="material-icons-outlined">share_location</i>
                                    </div>
                                </div>
                                  </div>
                                  {/* <div className='col-md-6 mt-3'>
                                    <h6>Coming Soon</h6>
                                  </div> */}
                                </div>
                             
                                <h5>AfterShip</h5>
                                <small>Customize your store tracking page</small>
                            </div>
                        </div>
                    </a>
                </div>
            </div>      
          </div>
        </section>
        
    </section>
    <Footer
                    showProgressBar={showProgressBar}
                    completedOrders={completedOrders}
                    totalOrders={totalOrders}
                    viewLogs={recordId}
                    status ={status}
                     toggleProgressBar={toggleProgressBar} 
                     showorderBar={showorderBar}
                     completedOrdersbulk={completedOrdersbulk}
                     totalOrdersbulk={totalOrdersbulk}
                     viewLogslogid={logidstate}
                     statusbulk ={statusstateorder}
                      toggleProgressBarbulk={toggleProgressBarbulk} 
                  />  
    

    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Tools
